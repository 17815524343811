import React from 'react';

function App() {
  const videoRef = React.useRef()

  React.useEffect(() => {
    async function getWebcamFeed() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false })
        videoRef.current.srcObject = stream;
      } catch (e) {
        console.log(e)
      }
    }

    getWebcamFeed();
  })

  // if (!isStreamSupported) {
  //   return (
  //     <div className="App">
  //       web stream not supported
  //     </div>
  //   );
  // }

  return (
    <div className="App">
      <button
        onClick={() => {
          console.log(videoRef.current);
          videoRef.current.requestPictureInPicture()
        }}
      >pip me!</button>
      <div>
        <video
          autoPlay
          ref={videoRef}
          style={{ transform: 'scaleX(-1)' }}
        />
      </div>
    </div>
  );
}

export default App;
